<template>
  <div class="share">
    <video
      class="share__bgvideo"
      autoplay
      muted
      loop
      playsinline
      id="video-share"
    >
      <source
        :src="`${$baseUrl}videos/landing-bg-video.mp4`"
        type="video/mp4"
      />
    </video>

    <IconButton
      class="share__close"
      icon="close"
      iconfill="stroke"
      :height="smallscreen ? '1.05rem' : '1.44rem'"
      @onClick="toggleShare(false)"
      :aria-label="$t('general.cta.close')"
    ></IconButton>

    <div class="share__label">{{ content.general.dida }}</div>
    <social-sharing
      :url="config.url"
      :title="config.title"
      :description="config.description"
      inline-template
    >
      <div class="share__container">
        <network class="social-name" network="facebook"> facebook </network>
        <network class="social-name" network="linkedin"> linkedin </network>
        <network class="social-name" network="twitter"> twitter </network>
      </div>
    </social-sharing>
  </div>
</template>

<script>
import IconButton from "@/components/UI/IconButton.vue";
import { mapActions } from "vuex";

export default {
  components: {
    IconButton
  },
  data() {
    return {
      config: {}
    };
  },
  created() {
    // console.log(window.location.href);
    // this.config.url = window.location.href;
    let meta = this.$t("share").general;
    meta.url = window.location.href;

    this.config = meta;
  },
  computed: {
    content() {
      console.log(this.$t("share").general.dida);
      return this.$t("share");
    },

    smallscreen() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    }
  },
  methods: {
    ...mapActions(["toggleShare"])
  }
};
</script>

<style lang="scss" scoped>
.share {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 115;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;

  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;

  &__label {
    // font-weight: normal !important;
    @include p-s;
  }

  &__close {
    position: absolute;
    top: 80px;
    .desktop &,
    .large & {
      top: 2rem;
      right: 3rem;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;

    .desktop &,
    .large & {
      height: 200px;
    }
    // border: 2px solid salmon;

    ::v-deep .social-name {
      text-decoration: underline;
      text-transform: capitalize;
      @include title-l;
      cursor: pointer;

      &:hover {
        cursor: pointer;
        text-shadow: $white-shadow;
      }

      .mobile &,
      .tablet & {
        margin-bottom: 40px;
      }
    }
  }

  &__bgvideo {
    @include video-styling;

    z-index: -1;
  }
}
</style>
