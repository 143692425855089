<template>
  <nav class="MenuLanding">
    <div
      class="MenuLanding__item"
      v-for="(item, i) in content"
      :key="item.label"
    >
      <a
        v-if="item.link"
        class="MenuLanding__item--label"
        :href="item.link"
        target="_blank"
      >
        {{ item.label.toUpperCase() }}
      </a>
      <button v-else class="MenuLanding__item--label" @click="click(item.name)">
        {{ item.label.toUpperCase() }}
      </button>
      <div
        class="MenuLanding__item--spacer"
        v-if="i < 2"
        :style="{
          backgroundImage: `url(${$baseUrl}images/menuSpacer.svg)`
        }"
      ></div>
    </div>
  </nav>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    content() {
      return this.$t("landing").menu;
    },
    ...mapGetters(["landingIndex"])
  },

  methods: {
    click(name) {
      if (name === "lang") {
        const newL = this.$i18n.locale === "en" ? "it" : "en";
        this.changeLanguage(newL);
      }

      if (name === "share") {
        this.toggleShare(true);
      }
    },
    changeLanguage(language) {
      // if (process.env.NODE_ENV === "development") {
      //[NB] only for dev because in production we'll use prerendering (?)
      this.$router.push({ params: { lang: language.toLowerCase() } });
      // }

      document.querySelector("html").setAttribute("lang", language);
      return (this.$i18n.locale = language);
    },
    ...mapActions(["toggleShare"])
  }
};
</script>
<style lang="scss" scoped>
$H: 35px;

$border: 1px solid $white;
.MenuLanding {
  width: fit-content;
  height: $H;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  user-select: none;

  color: $white;
  @include button-m-medium;

  &__item {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-of-type(1) {
      .MenuLanding__item--label {
        border-left: $border;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
      }
    }

    &:nth-of-type(3) {
      .MenuLanding__item--label {
        border-right: $border;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }
    }

    &--label {
      border-top: $border;
      border-bottom: $border;
      text-decoration: none;
      height: $H;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 25px;
      transition: $white-shadow-transition;

      &:hover {
        cursor: pointer;
        text-shadow: $white-shadow;
      }
    }

    &--spacer {
      width: 70px;
      height: $H;
      // height: calc(#{$H} * 1.2);

      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
}
</style>
