<template>
  <!-- :src="`${$baseUrl}images/menu_bg.svg`" -->
  <div
    alt=""
    class="menu-cloud"
    :style="{
      backgroundColor: theme ? `var(--primary-${theme})` : 'var(--white)',
      mask: `url(${$baseUrl}images/menu_bg.svg)`,
      '-webkit-mask': `url(${$baseUrl}images/menu_bg.svg)`,
      'mask-repeat': 'no-repeat',
      '-webkit-mask-repeat': 'no-repeat',
      'mask-size': 'cover',
      '-webkit-mask-size': 'cover'
    }"
  />
</template>

<script>
export default {
  props: {
    theme: String
  }
};
</script>

<style lang="scss" scoped>
.menu-cloud {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  pointer-events: none;
  z-index: 0;
  width: 100%;

  ::v-deep {
    fill: var(--cloud-color);
  }
}
</style>
