<template>
  <button class="ImmersionButton" @click="click()" :class="[theme]">
    <span>{{ label }}</span>
  </button>
</template>
<script>
// import { mapActions } from "vuex";
export default {
  props: {
    directionName: String,
    theme: String,
    label: String
  },
  methods: {
    click() {
      // this.toggleAutoScroll(false);
      this.$emit("click", this.directionName);

      // this.$router.push({ name: this.directionName });
    }
    // ...mapActions(["toggleAutoScroll"])
  }
};
</script>
<style lang="scss" scoped>
.ImmersionButton {
  @include elementOnTop;
  @include color-combinations;
  @include button-l;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $white 79.17%);
  filter: drop-shadow(#{$white-shadow});
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21.75rem;
  height: 3.13rem;
  border-radius: 12px;

  span {
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: inherit;
    transition: opacity 0.6s ease;
    background: linear-gradient(180deg, $white_40 0%, $white 79.17%);
    z-index: 0;
  }
  &:hover {
    &::after {
      opacity: 1;
    }
  }
}
</style>
