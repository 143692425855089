<template>
  <div class="LandingSea" :style="{ '--landing-color': 'var(--primary-sea)' }">
    <div
      :class="['LandingSea--cover', 'sea', BGanimationClass.sea]"
      :style="{
        zIndex: -1,
        mixBlendMode: 'luminosity',
        backgroundImage: `url(${$baseUrl}images/covers/sea/sea_down_1_luminosity.svg)`
      }"
    ></div>
    <div
      :class="['LandingSea--cover', 'sea', BGanimationOnlyFade]"
      :style="{
        zIndex: 1,
        mixBlendMode: 'multiply',
        backgroundImage: `url(${$baseUrl}images/covers/sea/sea_up_1_multiply.svg)`
      }"
    ></div>
    <div
      :class="['LandingSea--cover', 'sea', BGanimationOnlyFade]"
      :style="{
        zIndex: 1,
        mixBlendMode: 'multiply',
        backgroundImage: `url(${$baseUrl}images/covers/sea/sea_up_2_multiply.svg)`
      }"
    ></div>
    <div
      :class="['LandingSea--cover', 'sea', BGanimationClass.sea]"
      :style="{
        zIndex: 1,
        mixBlendMode: 'luminosity',
        backgroundImage: `url(${$baseUrl}images/covers/sea/sea_up_3_luminosity.svg)`
      }"
    ></div>
    <div
      :class="['LandingSea--cover', 'sea', BGanimationClass.sea]"
      :style="{
        zIndex: 1,
        mixBlendMode: 'luminosity',
        backgroundImage: `url(${$baseUrl}images/covers/sea/sea_up_4_luminosity.svg)`
      }"
    ></div>

    <h1
      :class="['landing-title', titleAnimationClass]"
      v-html="content.title"
    ></h1>
    <sub class="landing-subtitle" v-if="content.subtitle">
      {{ content.subtitle }}
    </sub>

    <!-- <div class="dida" v-if="content.dida[subIndex]">
      <h2 class="project" v-if="content.dida[subIndex].projectName">
        {{ content.dida[subIndex].projectName }}
      </h2>
      <p
        v-html="
          landingIndex === myIndex ? content.dida[subIndex].projectText : ''
        "
      />
    </div> -->

    <transition name="fade" mode="out-in">
      <CTMSlider
        v-if="landingIndex === myIndex"
        :content="content.dida"
        :theme="'sea'"
      />
    </transition>

    <ImmersionButton
      class="immersion-btn"
      :label="content.cta"
      :directionName="content.to"
      theme="sea"
      @click="immersionBtnClick"
    />

    <!-- <AudioPlayer
      v-if="!smallscreen"
      class="landing-player"
      :audioTrack="'sea'"
      theme="sea"
      :autoPlay="false"
    /> -->
  </div>
</template>
<script>
import ImmersionButton from "@/components/UI/ImmersionButton";
import CTMSlider from "./CTMSlider.vue";
import { animationHelper } from "@/mixins/bg-animationHelper";

export default {
  components: {
    ImmersionButton,
    CTMSlider
  },
  mixins: [animationHelper],

  data() {
    return {
      myIndex: 7
    };
  },

  computed: {
    content() {
      return this.$t("sea").intro;
    },
    smallscreen() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    }
  }
};
</script>
<style lang="scss" scoped>
.LandingSea {
  @include landing-element;
  .dida {
    ::v-deep b {
      color: $primary-sea;
    }
  }
}
</style>
