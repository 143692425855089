<template>
  <div
    class="LandingEarth"
    :style="{ '--landing-color': 'var(--primary-earth)' }"
  >
    <div
      :class="['LandingEarth--cover', 'earth', BGanimationClass.earth]"
      :style="{
        zIndex: 2,
        backgroundImage: `url(${$baseUrl}images/covers/earth/earth_down_1.svg)`
      }"
    ></div>
    <div
      :class="['LandingEarth--cover', 'earth', BGanimationClass.earth]"
      :style="{
        zIndex: 3,
        backgroundImage: `url(${$baseUrl}images/covers/earth/earth_up_1.svg)`
      }"
    ></div>
    <div
      :class="['LandingEarth--cover', 'earth', BGanimationClass.earth]"
      :style="{
        zIndex: 2,
        backgroundImage: `url(${$baseUrl}images/covers/earth/earth_up_2.svg)`
      }"
    ></div>
    <div
      :class="['LandingEarth--cover', 'earth', BGanimationClass.earth]"
      :style="{
        zIndex: 1,
        backgroundImage: `url(${$baseUrl}images/covers/earth/earth_up_3.svg)`
      }"
    ></div>

    <h1
      :class="['landing-title', titleAnimationClass]"
      v-html="content.title"
    ></h1>
    <sub class="landing-subtitle" v-if="content.subtitle">
      {{ content.subtitle }}
    </sub>

    <!-- <div class="dida" v-if="content.dida[subIndex]">
      <h2 class="project" v-if="content.dida[subIndex].projectName">
        {{ content.dida[subIndex].projectName }}
      </h2>
      <p
        v-html="
          landingIndex === myIndex ? content.dida[subIndex].projectText : ''
        "
      />
    </div> -->

    <transition name="fade" mode="out-in">
      <CTMSlider
        v-if="landingIndex === myIndex"
        :content="content.dida"
        :theme="'earth'"
      />
    </transition>

    <ImmersionButton
      class="immersion-btn"
      :label="content.cta"
      :directionName="content.to"
      theme="earth"
      @click="immersionBtnClick"
    />

    <!-- <AudioPlayer
      v-if="!smallscreen"
      class="landing-player"
      :audioTrack="'earth'"
      theme="earth"
      :autoPlay="false"
    /> -->
  </div>
</template>
<script>
import ImmersionButton from "@/components/UI/ImmersionButton";
import { animationHelper } from "@/mixins/bg-animationHelper";
import CTMSlider from "./CTMSlider.vue";

export default {
  mixins: [animationHelper],

  data() {
    return {
      myIndex: 6
    };
  },

  components: {
    ImmersionButton,
    CTMSlider
  },
  computed: {
    content() {
      return this.$t("earth").intro;
    },
    smallscreen() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    }
  }
};
</script>
<style lang="scss" scoped>
.LandingEarth {
  @include landing-element;
  .dida {
    ::v-deep b {
      color: $primary-earth;
    }
  }
}
</style>
