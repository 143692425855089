<template>
  <div class="Landing" v-touch:start="onSwipeStart" v-touch:moving="onSwipe">
    <transition name="fade" mode="out-in">
      <div
        class="Landing__header"
        v-show="landingIndex > 0 && landingIndex <= 4"
      >
        <div class="Landing__header__top">
          <button @click="toLandingMain">
            <img :src="`${$baseUrl}images/ctm-logo.svg`" alt="logo di Terna" />
          </button>
          <MenuLanding v-if="!isMobileOrTablet"></MenuLanding>

          <button
            v-if="isMobileOrTablet"
            :aria-label="
              $t(`general.cta.${menuOpen ? 'close' : 'open'}`) + ' menu'
            "
            @click="menuOpen = !menuOpen"
          >
            <svgicon
              :name="menuOpen ? 'close' : 'hamburger'"
              :height="menuOpen ? '1.5rem' : '2rem'"
              color="r-var(--white)"
            />
          </button>
        </div>
        <div class="Landing__header__bottom" v-if="isMobileOrTablet">
          <div class="Landing__header__bottom--fadingLine"></div>
          <div class="Landing__header__bottom--fadingLine"></div>
        </div>
      </div>
    </transition>

    <transition :name="'fade'" mode="out-in">
      <LandingContent v-if="landingIndex <= 4" :transition="transition" />
      <LandingCTM v-if="landingIndex > 4" :currentIndex="landingIndex" />
    </transition>

    <transition name="menu-transition">
      <Menu v-if="landingIndex > 4" :index="landingIndex" />
    </transition>

    <transition name="fade">
      <MenuLandingMobile v-if="menuOpen" />
    </transition>

    <transition name="fade">
      <Share v-if="isShareOpen"></Share>
    </transition>
  </div>
</template>
<script>
// 0-4 landingContent
// 5-7 cover
import MenuLanding from "@/components/UI/landing/MenuLanding.vue";
import LandingContent from "@/components/UI/landing/LandingContent.vue";
import Menu from "@/components/UI/Menu";
import LandingCTM from "@/components/UI/landing/LandingCTM";
import Share from "@/components/UI/Share";
import MenuLandingMobile from "@/components/UI/landing/MenuLandingMobile.vue";

import { mapGetters, mapActions } from "vuex";
import { throttle as _throttle } from "lodash";
import { debounce as _debounce } from "lodash";

export default {
  mounted() {
    if (!this.isWheelListenerInitialized) {
      // document.addEventListener("wheel", this.onMouseWheel);
      document.addEventListener(
        "wheel",
        _throttle(this.onMouseWheel, 500, {
          leading: true,
          trailing: false
        })
      );

      document.addEventListener("wheel", _debounce(this.stoppedScrolling, 100));
      this.setWheelListener(true);
    }
  },
  computed: {
    isMobileOrTablet() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    },
    isMobile() {
      return this.$tvaMq === "mobile";
    },
    sections() {
      return this.$t("menu");
    },
    ...mapGetters(["landingIndex", "isWheelListenerInitialized", "isShareOpen"])
  },
  data() {
    return {
      timeout: null,
      prevTouch: { x: 0, y: 0 },
      navigated: false,
      transition: "bottom-to-top",
      menuOpen: false
    };
  },
  metaInfo() {
    return this.$t("share").general;
  },
  watch: {
    landingIndex(newV, oldV) {
      if (newV > oldV) {
        this.transition = "top-to-bottom";
      } else {
        this.transition = "bottom-to-top";
      }
    }
  },
  methods: {
    toLandingMain() {
      this.menuOpen = false;
      this.setIndexFromMenu(0);
    },
    stoppedScrolling() {
      this.navigated = false;
    },
    onMouseWheel(e) {
      if (!this.navigated) {
        this.navigated = true;

        this.navigateLanding(e.deltaY, 0);
      }

      // clearTimeout(this.timeout);

      // if (!this.timeout) {
      //   this.navigateLanding(e.deltaY, 0);
      // }

      // this.timeout = setTimeout(() => {
      //   this.timeout = null;
      // }, 50);
    },
    // In order to navigate in the correct direction, we have to set prevTouch.y once on swipe start
    onSwipeStart(e) {
      if (e.touches && e.touches.length > 0) {
        const touch = e.touches[0];
        this.navigated = false;
        this.prevTouch.x = touch.clientX;
        this.prevTouch.y = touch.clientY;
      }
    },
    onSwipe(e) {
      if (
        e.touches &&
        e.touches.length > 0 &&
        !this.navigated &&
        !this.menuOpen
      ) {
        const touch = e.touches[0];

        // This condition is to only fire navigation when swiping up and down, not left to right
        // Otherwise there will be a conflict with swiping on the slider
        if (
          (this.prevTouch.y - touch.clientY > 5 ||
            this.prevTouch.y - touch.clientY < -5) &&
          this.prevTouch.x - touch.clientX < 5 &&
          this.prevTouch.x - touch.clientX > -5
        ) {
          this.navigateLanding(this.prevTouch.y, touch.clientY);
          this.navigated = true;
        }
      }
    },
    navigateLanding(eventY, compareValue) {
      if (this.$route.name === "landing") {
        if (eventY > compareValue) {
          this.increment();
        } else if (eventY < compareValue && this.landingIndex !== 0) {
          this.decrement();
        }
      }
    },
    ...mapActions([
      "increment",
      "decrement",
      "setIndexFromMenu",
      "setWheelListener"
    ])
  },
  components: {
    MenuLanding,
    MenuLandingMobile,
    LandingContent,
    Menu,
    LandingCTM,
    Share
  }
};
</script>
<style lang="scss" scoped>
.Landing {
  // background-color: rgb(3, 3, 51); // just to see the menu
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;

  .menu-transition-enter-active {
    transition: transform 0.8s ease 1s;
  }

  .menu-transition-leave-active {
    transition: transform 0.8s ease;
  }

  .menu-transition-enter,
  .menu-transition-leave-to {
    ::v-deep .Menu__cloud {
      opacity: 0;
    }

    transform: translateX(calc(-1 * (var(--menu-width) + 2px)));
  }

  .menu-transition-enter-to {
    ::v-deep .Menu__cloud {
      @keyframes fade-in {
        from {
          opacity: 0;
        }
      }

      animation: fade-in 0.8s 1s both;
    }
  }

  .menu-transition-leave-to {
    ::v-deep .Menu__cloud {
      @keyframes fade-out {
        to {
          opacity: 0;
        }
      }

      animation: fade-out 0.8s both;
    }
  }

  &__header {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5rem 1.87rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 2;

    .tablet &,
    .mobile & {
      padding: 15px;
    }

    &__top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      filter: drop-shadow($white-shadow);

      img {
        cursor: pointer;
        .mobile & {
          width: 50px;
        }
      }
    }

    &__bottom {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 15px;

      &--fadingLine {
        flex-grow: 1;
        height: 1px;
        align-self: flex-end;

        @include effect-style-bright;
        @include fadingLines(0.8, 1);

        &:nth-of-type(2) {
          @include fadingLines(0.8, -1);
        }
      }
    }
  }
}
</style>
