<template>
  <nav class="menu-landing-mobile">
    <ol class="menu-landing-mobile__links">
      <li
        class="menu-landing-mobile__links__link"
        v-for="item in content"
        :key="item.name"
      >
        <a v-if="item.link" :href="item.link" target="_blank">
          {{ item.label }}
        </a>
        <button v-else @click="onMenuItemClick(item.name)">
          {{ item.label }}
        </button>
      </li>
    </ol>
    <LandingNotes />
  </nav>
</template>

<script>
import { mapActions } from "vuex";
import LandingNotes from "@/components/UI/landing/LandingNotes.vue";
export default {
  computed: {
    content() {
      return this.$t("landing").menu;
    }
  },
  methods: {
    onMenuItemClick(name) {
      if (name === "share") {
        this.toggleShare(true);
      }

      if (name === "lang") {
        const newL = this.$i18n.locale === "en" ? "it" : "en";
        this.changeLanguage(newL);
      }
    },
    changeLanguage(language) {
      this.$router.push({ params: { lang: language.toLowerCase() } });

      document.querySelector("html").setAttribute("lang", language);
      return (this.$i18n.locale = language);
    },
    ...mapActions(["toggleShare"])
  },
  components: {
    LandingNotes
  }
};
</script>

<style lang="scss" scoped>
.menu-landing-mobile {
  $menu-height: 4.4rem;

  @include p-m;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(2, 13, 115, 0.31);
  backdrop-filter: blur(24px);
  z-index: 1;
  padding-top: $menu-height;

  &__links {
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 100px);

    &__link {
      $line-width: 35%;

      position: relative;
      flex-grow: 1;
      display: flex;
      justify-content: center;

      > a {
        display: flex;
        align-items: center;
      }

      &:not(:last-child) {
        &:before,
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: $line-width;
          height: 1px;
        }

        &:before {
          left: calc(50% - #{$line-width});

          @include effect-style-bright;
          @include fadingLines(0.8, 1);
        }

        &:after {
          left: 50%;

          @include effect-style-bright;
          @include fadingLines(0.8, -1);
        }
      }
    }
  }
}
</style>
