var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"LandingSea",style:({ '--landing-color': 'var(--primary-sea)' })},[_c('div',{class:['LandingSea--cover', 'sea', _vm.BGanimationClass.sea],style:({
      zIndex: -1,
      mixBlendMode: 'luminosity',
      backgroundImage: ("url(" + _vm.$baseUrl + "images/covers/sea/sea_down_1_luminosity.svg)")
    })}),_c('div',{class:['LandingSea--cover', 'sea', _vm.BGanimationOnlyFade],style:({
      zIndex: 1,
      mixBlendMode: 'multiply',
      backgroundImage: ("url(" + _vm.$baseUrl + "images/covers/sea/sea_up_1_multiply.svg)")
    })}),_c('div',{class:['LandingSea--cover', 'sea', _vm.BGanimationOnlyFade],style:({
      zIndex: 1,
      mixBlendMode: 'multiply',
      backgroundImage: ("url(" + _vm.$baseUrl + "images/covers/sea/sea_up_2_multiply.svg)")
    })}),_c('div',{class:['LandingSea--cover', 'sea', _vm.BGanimationClass.sea],style:({
      zIndex: 1,
      mixBlendMode: 'luminosity',
      backgroundImage: ("url(" + _vm.$baseUrl + "images/covers/sea/sea_up_3_luminosity.svg)")
    })}),_c('div',{class:['LandingSea--cover', 'sea', _vm.BGanimationClass.sea],style:({
      zIndex: 1,
      mixBlendMode: 'luminosity',
      backgroundImage: ("url(" + _vm.$baseUrl + "images/covers/sea/sea_up_4_luminosity.svg)")
    })}),_c('h1',{class:['landing-title', _vm.titleAnimationClass],domProps:{"innerHTML":_vm._s(_vm.content.title)}}),(_vm.content.subtitle)?_c('sub',{staticClass:"landing-subtitle"},[_vm._v(" "+_vm._s(_vm.content.subtitle)+" ")]):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.landingIndex === _vm.myIndex)?_c('CTMSlider',{attrs:{"content":_vm.content.dida,"theme":'sea'}}):_vm._e()],1),_c('ImmersionButton',{staticClass:"immersion-btn",attrs:{"label":_vm.content.cta,"directionName":_vm.content.to,"theme":"sea"},on:{"click":_vm.immersionBtnClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }