<template>
  <div
    class="ctm-slider"
    ref="slider"
    :style="{
      '--theme-color': `var(--primary-${theme})`,
      '--background-color': `var(--primary-${theme}_10)`
    }"
  >
    <div
      class="ctm-slider__card"
      v-for="item of content"
      :key="(item.projectName || theme) + '_slider'"
    >
      <p v-html="getProjectNameHTML(item.projectName) + item.projectText" />
    </div>
  </div>
</template>

<script>
import Flickity from "flickity-imagesloaded";
import { debounce as _debounce } from "lodash";

export default {
  props: {
    content: {
      type: Array,
      default: () => []
    },
    theme: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      flkty: null,
      options: {
        draggable: ">1",
        prevNextButtons: false,
        adaptiveHeight: false,
        fade: false,
        autoPlay: 4000
      }
    };
  },
  mounted() {
    this.$nextTick(this.createFlickity);
    // this.createFlickity();

    window.addEventListener("resize", _debounce(this.createFlickity));
  },

  methods: {
    getProjectNameHTML(project) {
      return !!project
        ? `<span class='ctm-slider__card__project'>${project}. </span>`
        : "";
    },
    createFlickity() {
      var elem = this.$refs.slider;

      if (elem && !this.isFlickity) {
        this.flkty = new Flickity(elem, this.options);

        this.flkty.on("change", index => {
          this.index = index;
        });

        this.isFlickity = true;
      } else if (!elem && this.isFlickity) {
        this.flkty.destroy();
        this.flkty = null;
        this.isFlickity = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ctm-slider {
  $slider-width: 56rem;

  @include elementOnTop;

  height: 15%;
  width: 100%;
  max-width: $slider-width;
  outline: none;

  &__card {
    background-color: var(--background-color);
    width: 100%;
    max-width: calc(#{$slider-width} - 1rem);
    height: 9.75rem;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: bold;
    letter-spacing: -0.02em;
    color: $white;
    border-radius: 12px;
    padding: 0.75rem 1rem;
    margin: 0 0.5rem;
    text-align: left;
    position: absolute;
    bottom: 0;

    ::v-deep .ctm-slider__card__project {
      text-transform: uppercase;
    }
  }

  .mobile &,
  .tablet & {
    width: calc(100% - var(--menu-width) - 2rem);
    left: 1.75rem;

    .ctm-slider__card {
      position: relative;
      bottom: initial;
      height: initial;
    }
  }

  .mobile & {
    height: auto;
    // top: 5vh;
    // top: calc(var(--vh, 1vh) * 5);
    position: absolute;
    bottom: 3rem;
    left: 4.5rem;

    .ctm-slider__card {
      @include p-xxs;
    }
  }
}
</style>

<style lang="scss">
*:focus-visible {
  // Sorry, this is not accessible, but I can't seem to find a way to hide
  // the outline otherwise when clicking/dragging with the mouse
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  min-height: 100%;

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }

  .flickity-slider {
    position: relative;
    height: 100%;
  }
}

.flickity-page-dots {
  display: flex;
  justify-content: center;
  margin-top: 0.75rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  .dot {
    width: 4rem;
    height: 0.25rem;
    border-radius: 3px;
    margin: 0 0.25rem;
    background-color: $white;
    cursor: pointer;

    &:hover {
      background-color: var(--background-color);
    }

    &.is-selected {
      background-color: var(--theme-color);
    }
  }

  .tablet &,
  .mobile & {
    top: 0;
    transform: translate(-50%, -250%);

    .dot {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      margin: 0 0.6rem;
    }
  }
}
</style>
