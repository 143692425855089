var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"LandingEarth",style:({ '--landing-color': 'var(--primary-earth)' })},[_c('div',{class:['LandingEarth--cover', 'earth', _vm.BGanimationClass.earth],style:({
      zIndex: 2,
      backgroundImage: ("url(" + _vm.$baseUrl + "images/covers/earth/earth_down_1.svg)")
    })}),_c('div',{class:['LandingEarth--cover', 'earth', _vm.BGanimationClass.earth],style:({
      zIndex: 3,
      backgroundImage: ("url(" + _vm.$baseUrl + "images/covers/earth/earth_up_1.svg)")
    })}),_c('div',{class:['LandingEarth--cover', 'earth', _vm.BGanimationClass.earth],style:({
      zIndex: 2,
      backgroundImage: ("url(" + _vm.$baseUrl + "images/covers/earth/earth_up_2.svg)")
    })}),_c('div',{class:['LandingEarth--cover', 'earth', _vm.BGanimationClass.earth],style:({
      zIndex: 1,
      backgroundImage: ("url(" + _vm.$baseUrl + "images/covers/earth/earth_up_3.svg)")
    })}),_c('h1',{class:['landing-title', _vm.titleAnimationClass],domProps:{"innerHTML":_vm._s(_vm.content.title)}}),(_vm.content.subtitle)?_c('sub',{staticClass:"landing-subtitle"},[_vm._v(" "+_vm._s(_vm.content.subtitle)+" ")]):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.landingIndex === _vm.myIndex)?_c('CTMSlider',{attrs:{"content":_vm.content.dida,"theme":'earth'}}):_vm._e()],1),_c('ImmersionButton',{staticClass:"immersion-btn",attrs:{"label":_vm.content.cta,"directionName":_vm.content.to,"theme":"earth"},on:{"click":_vm.immersionBtnClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }