<template>
  <div
    :class="['LandingSky']"
    :style="{ '--landing-color': 'var(--primary-sky_60)' }"
  >
    <div
      :class="['LandingSky--cover', 'sky', BGanimationClass.sky]"
      :style="{
        backgroundImage: `url(${$baseUrl}images/covers/sky/sky_up_1.svg)`
      }"
    ></div>
    <div
      :class="['LandingSky--cover', 'sky', BGanimationClass.sky]"
      :style="{
        backgroundImage: `url(${$baseUrl}images/covers/sky/sky_up_2.svg)`
      }"
    ></div>

    <h1 :class="['landing-title', titleAnimationClass]">
      {{ content.title }}
    </h1>
    <sub class="landing-subtitle" v-if="content.subtitle">
      {{ content.subtitle }}
    </sub>

    <transition name="fade" mode="out-in">
      <CTMSlider
        v-if="landingIndex === myIndex"
        :content="content.dida"
        :theme="'sky'"
      />
    </transition>

    <!-- <div class="dida" v-if="content.dida[subIndex]">
      <h2 class="project" v-if="content.dida[subIndex].projectName">
        {{ content.dida[subIndex].projectName }}
      </h2>
      <p
        v-html="
          landingIndex === myIndex ? content.dida[subIndex].projectText : ''
        "
      />
    </div> -->

    <ImmersionButton
      class="immersion-btn"
      :label="content.cta"
      :directionName="content.to"
      theme="sky"
      @click="immersionBtnClick"
    />

    <!-- <AudioPlayer
      v-if="!smallscreen"
      class="landing-player"
      :audioTrack="'sky'"
      theme="sky"
      :autoPlay="false"
    /> -->
  </div>
</template>
<script>
import ImmersionButton from "@/components/UI/ImmersionButton";
import { animationHelper } from "@/mixins/bg-animationHelper";
import CTMSlider from "./CTMSlider.vue";

export default {
  components: {
    ImmersionButton,
    CTMSlider
  },
  mixins: [animationHelper],
  data() {
    return {
      myIndex: 5
    };
  },

  computed: {
    content() {
      return this.$t("sky").intro;
    },
    smallscreen() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    }
  }
};
</script>
<style lang="scss" scoped>
.LandingSky {
  @include landing-element;
  &--cover {
    height: 200vh;
    height: calc(var(--vh, 1vh) * 200);
    top: 0;
  }

  .dida {
    ::v-deep b {
      color: $primary-sky;
    }
  }
}
</style>
