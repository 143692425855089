<template>
  <div class="LandingCTM">
    <div class="LandingCTM__logo">
      <img :src="`${$baseUrl}images/LogoTerna.svg`" alt="logo di Terna" />
    </div>
    <div
      :class="['LandingCTM__scenes-container', transitionClass]"
      :style="{ '--ctm-translate': finalTranslate }"
    >
      <LandingSky />

      <div class="LandingCTM__spacing" />

      <LandingEarth />
      <div class="LandingCTM__spacing" />
      <LandingSea />
    </div>
  </div>
</template>

<script>
import LandingSky from "./LandingSky";
import LandingEarth from "./LandingEarth";
import LandingSea from "./LandingSea";
import { indexHelper } from "@/mixins/ui-indexHelper";
import { animationHelper } from "@/mixins/bg-animationHelper";
export default {
  props: {
    currentIndex: Number
  },
  mixins: [indexHelper, animationHelper],
  computed: {
    dynamicContent() {
      const name = this.mappingClasses[parseInt(this.currentIndex)];
      return this.$t(name).intro.labelIn.toUpperCase();
    },
    smallscreen() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    }
  },
  components: {
    LandingSky,
    LandingEarth,
    LandingSea
  }
};
</script>

<style lang="scss" scoped>
.LandingCTM {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;

  &__logo {
    @include p-m;

    pointer-events: none;
    position: fixed;
    top: 1.87rem;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    text-shadow: 0px 0px 10px $white_70;
  }

  &__scenes-container {
    transform: translateY(calc(var(--ctm-translate) * 1vh));
    transform: translateY(calc(var(--vh, 1vh) * var(--ctm-translate)));
    transition: transform ease-in-out 1s;
    height: 320vh;
    height: calc(var(--vh, 1vh) * 320);

    &.standard {
      transition-duration: 1s;
    }

    &.slower {
      transition-duration: 2.5s;
    }
  }

  &__spacing {
    height: 10vh;
    height: calc(var(--vh, 1vh) * 10);
    width: 100%;
  }

  .mobile &,
  .tablet & {
    .LandingCTM__logo {
      @include p-s;

      font-weight: bold;
    }
  }
}
</style>
