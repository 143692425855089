<template>
  <nav
    class="Menu"
    :class="[dynamicClass(index)]"
    :style="{ '--theme-color': `var(--primary-${dynamicClass(index)})` }"
  >
    <MenuCloud class="Menu__cloud" />

    <button
      @click="changeIndex(null, 0)"
      aria-label="To home"
      class="Menu__logo"
    >
      <svgicon name="ctm-logo" width="3.12rem" :class="[dynamicClass(index)]" />
    </button>
    <div class="Menu__labels-container">
      <div v-for="el in content" :key="el.name" class="Menu__label__container">
        <button
          class="Menu__label"
          :class="{ 'is-active': el.name === dynamicClass(index) }"
          @click="changeIndex(el.name)"
        >
          {{ el.label }}
        </button>
      </div>
      <div
        class="Menu__labels-container__active"
        :style="{
          '--active-translate-factor': activeTranslateFactor,
          '--active-translate-offset': activeTranslateOffset
        }"
      />
    </div>
  </nav>
</template>

<script>
import { indexHelper } from "@/mixins/ui-indexHelper";
import MenuCloud from "./3DScenes/MenuCloud.vue";

export default {
  components: { MenuCloud },
  mixins: [indexHelper],
  computed: {
    content() {
      return this.$t("menu");
    },
    activeTranslateFactor() {
      return (this.index - 5) * 0.5;
    },
    activeTranslateOffset() {
      return (this.index - 4) * -25 + "%";
    }
  },
  props: {
    index: Number
  }
};
</script>

<style lang="scss" scoped>
.Menu {
  position: absolute;
  left: 0;
  top: 0;
  height: calc(100vh - 2px);
  height: calc(var(--vh, 1vh) * 100 - 2px);
  width: var(--menu-width);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) -3.27%,
    rgba(255, 255, 255, 0.5) 100%
  );
  color: var(--theme-color);
  border-right: 1px solid var(--theme-color);
  transition: color 0.8s ease;

  &__label {
    @include button-m-bold-serif;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: normal;
    // text-shadow: 0px 0px 7px var(--theme-color);

    transition: color 0.8s ease;

    &.is-active,
    &:hover {
      font-weight: 600;
    }

    &__container {
      height: var(--menu-width);
      display: flex;
      justify-content: center;
      align-items: center;
      transform-origin: center;
      transform: rotate(270deg);
      position: relative;
    }
  }
  &__labels-container {
    --container-offset: 0px;
    --top-bar: 44px;

    // 30px is offset top bar from top
    // 1 rem is small padding at bottom of screen
    // var(--menu-width) is padding of __labels-container
    --active-offset: calc(
      30px + var(--top-bar) + (var(--menu-width) * 2) + 1rem
    );

    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100% - (160px - 75px));
    justify-content: space-between;
    padding: var(--menu-width) 0;

    &__active {
      width: 6px;
      height: 8rem;
      position: absolute;
      top: calc(var(--menu-width));
      right: -3px;
      background-color: var(--theme-color);
      transform: translateY(
        calc(
          var(--active-translate-factor) *
            (var(--vh, 1vh) * 100 - var(--active-offset)) +
            var(--active-translate-offset)
        )
      );
      transition: transform 0.8s ease;

      .sea & {
        box-shadow: 0px 0px 7px rgba(104, 111, 255, 0.6);
      }
      .earth & {
        box-shadow: 0px 0px 7px rgba(114, 185, 117, 0.6);
      }
      .sky & {
        box-shadow: 0px 0px 7px rgba(104, 111, 255, 0.6);
      }
    }
  }
  &__logo {
    z-index: 1;
    margin-top: 2.19rem;
    filter: drop-shadow(0px 0px 7px var(--theme-color));
    transition: fill 0.8s ease, filter 0.3s;

    .sea {
      fill: $primary-sea;
    }
    .earth {
      fill: $primary-earth;
    }
    .sky {
      fill: $primary-sky;
    }

    &:hover {
      cursor: pointer;
      filter: drop-shadow(0px 0px 7px var(--theme-color))
        drop-shadow(0px 0px 1px var(--theme-color));
    }
  }

  .mobile &,
  .tablet & {
    --container-width: 3.5rem;

    .Menu__labels-container {
      --top-bar: 35px;
    }
  }
}
</style>
