var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-cloud",style:({
    backgroundColor: _vm.theme ? ("var(--primary-" + _vm.theme + ")") : 'var(--white)',
    mask: ("url(" + _vm.$baseUrl + "images/menu_bg.svg)"),
    '-webkit-mask': ("url(" + _vm.$baseUrl + "images/menu_bg.svg)"),
    'mask-repeat': 'no-repeat',
    '-webkit-mask-repeat': 'no-repeat',
    'mask-size': 'cover',
    '-webkit-mask-size': 'cover'
  }),attrs:{"alt":""}})}
var staticRenderFns = []

export { render, staticRenderFns }