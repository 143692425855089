<template>
  <router-link
    v-if="link"
    class="icon-button"
    :style="cssColors"
    :aria-label="label"
    :to="link"
  >
    <svgicon :name="icon" :height="height" :color="iconColor" />
  </router-link>

  <button
    v-else
    class="icon-button"
    :style="cssColors"
    :aria-label="label"
    :disabled="disabled"
    @click.stop="$emit('onClick')"
  >
    <svgicon :name="icon" :height="height" :color="iconColor" />
  </button>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "--white"
    },

    iconfill: {
      type: String,
      default: "fill",
      validator: value => ["fill", "stroke", "both"].includes(value)
    },
    height: {
      type: String,
      default: "1.75rem"
    },
    label: String,
    link: Object,
    disabled: Boolean
  },
  computed: {
    iconColor() {
      const fill = `var(${this.color})`;
      if (this.iconfill === "stroke") {
        return `r-${fill}`;
      } else if (this.iconfill === "both") {
        return `${fill} r-${fill}`;
      }

      return fill;
    },
    cssColors() {
      return {
        "--icon-border-color": `var(${this.color})`,
        "--icon-bg-color": `var(${this.color}_10)`,
        "--icon-hover-color": `var(${this.color}_40)`
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.icon-button {
  position: relative;
  border: 1px solid var(--icon-border-color);
  background-color: var(--icon-bg-color);
  border-radius: 15px;
  width: $icon-btn-size;
  height: $icon-btn-size;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;

  // Add after to enable performant background fade in (i.e. using opacity, not background-color)
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: var(--icon-hover-color);
    opacity: 0;
    transition: opacity 0.5s;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:hover:not(:disabled)::after {
    opacity: 1;
  }

  .mobile & {
    width: $icon-btn-size;
    height: $icon-btn-size;
    border-radius: 10px;

    &::after {
      border-radius: 10px;
    }
  }
}
</style>
